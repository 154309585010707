<template>
    <KCourse loader-class="MBcont"
             chapter-id=89
             chapterPayment="/Membership/Beginner2/MBCourseBeginnerChapterPayment.vue"
             course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Соединяем боковые швы платья</h3>
        </div>
                
        <VideoView video-id="928f3eea9d5645f2b51535f00ac8a159"/>

        <div class="MBtextcont">
            <h3>2. Подготавливаем оборку</h3>
        </div>

        <VideoView video-id="0cf883172a3a4b9bb1b0fdafb68547c8"/>

        <div class="MBtextcont">
            <h3>3. Соединяем оборку с платьем</h3>
        </div>

        <VideoView video-id="e37bf253fd714839982c3c12fc29a670"/>

        <div class="MBtextcont">
            <h3>4. Ревизия всех деталей платья</h3>
        </div>

        <VideoView video-id="19e2eaaeb31742b9a4581f1605869d98"/>

        <div class="MBtextcont">
            <h3>5. Шьем рукав</h3>
        </div>

        <VideoView video-id="89d106e600704de9b3502bc481024188"/>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Стачать боковые швы</li>
                <li>Пришить оборку</li>
                <li>Сделать рукав</li>
            </ol>

           <h3>Время выполнения: 6 часов</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>