
const items = [
        { title: "Подготовка", url:"/mycourses/beginner2/1", chapter_id:66, state: "", payment_step:2},
        { title: "Образ", url:"/mycourses/beginner2/2", chapter_id:67, state: "", payment_step:2},
        { title: "Чертеж и трафареты", url:"/mycourses/beginner2/3", chapter_id:68, state: "", payment_step:2},
        { title: "Пластилиновые заготовки", url:"/mycourses/beginner2/4", chapter_id: 69, state: "", payment_step:2},
        { title: "Обтяжка LaDoll", url:"/mycourses/beginner2/5", chapter_id: 70, state: "", payment_step:1},
        { title: "Руки, ноги", url:"/mycourses/beginner2/6", chapter_id: 71, state: "", payment_step:1},
        { title: "Анатомия заготовок", url:"/mycourses/beginner2/7", chapter_id: 72, state: "", payment_step:1},
        { title: "Голова + грудь", url:"/mycourses/beginner2/8", chapter_id: 73, state: "", payment_step:1},
        { title: "Голова + шарики", url:"/mycourses/beginner2/9", chapter_id: 74, state: "", payment_step:1},
        { title: "Голова + шарниры", url:"/mycourses/beginner2/10", chapter_id: 75, state: "", payment_step:1},
        { title: "Голова + шарниро-приемники", url:"/mycourses/beginner2/11", chapter_id: 76, state: "", payment_step:1},
        { title: "Перемычки, крючки", url:"/mycourses/beginner2/12", chapter_id: 77, state: "", payment_step:0},
        { title: "Первая сборка", url:"/mycourses/beginner2/13", chapter_id: 78, state: "", payment_step:0},
        { title: "Снятие выкройки", url:"/mycourses/beginner2/14", chapter_id: 79, state: "", payment_step:0},
        { title: "Грунтуем, красим", url:"/mycourses/beginner2/15", chapter_id: 80, state: "", payment_step:0},
        { title: "Расписываем", url:"/mycourses/beginner2/16", chapter_id: 81, state: "", payment_step:0},
        { title: "Делаем парик", url:"/mycourses/beginner2/17", chapter_id: 82, state: "", payment_step:0},
        { title: "Собираем", url:"/mycourses/beginner2/18", chapter_id: 83, state: "", payment_step:0},
        { title: "Ручные швы", url:"/mycourses/beginner2/19", chapter_id: 84, state: "", payment_step:0}, // Съемный костюм
        { title: "Последние штрихи", url:"/mycourses/beginner2/20", chapter_id: 85, state: "", payment_step:0},
        { title: "Машинные швы", url:"/mycourses/beginner2/21", chapter_id: 86, state: "", payment_step:0}, // Съемный костюм
        { title: "Утюжка", url:"/mycourses/beginner2/22", chapter_id: 87, state: "", payment_step:0}, // Съемный костюм
        { title: "Создание выкройки", url:"/mycourses/beginner2/23", chapter_id: 88, state: "", payment_step:0},
        { title: "Пошив платья", url:"/mycourses/beginner2/24", chapter_id: 89, state: "", payment_step:0}, 
        { title: "Делаем обувь", url:"/mycourses/beginner2/25", chapter_id: 90, state: "", payment_step:0}, 
    ];

export default items;